/* src/styles/LandingPage.css */

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Main Container */
.landing-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #050b1b 0%, #061121 100%);
  color: #ffffff;
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  /* background: rgba(15, 23, 42, 0.9); */
  background: rgba(1, 5, 14, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(233, 232, 232, 0.253);
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
}

.nav-logo-img {
  width: 2.5rem;
  height: 2.5rem;
  animation: spin-slow 10s linear infinite;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color:#42c4e3  ;

}

.nav-links a:hover {
  color: #60a5fa;
  background: rgba(96, 165, 250, 0.1);
}

.nav-toggle {
  display: none;
  cursor: pointer;
  color: white;
}

/* Content Wrapper */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 1rem 2rem;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 4rem 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Logo Container */
.logo-container {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin: 0 auto 1rem;
  border-radius: 50%;
  background: rgba(30, 41, 59, 0.5);
  /* background: rgba(101, 112, 131, 0); */
  padding: 0.2rem;
  animation: spin-slow 10s linear infinite;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Company Title and Tagline */
.company-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  /* background: linear-gradient(135deg, #60a5fa 0%, #3b82f6 100%); */
  background: linear-gradient(135deg, #42c4e3 0%, #42c4e3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.company-tagline {
  font-size: 1.25rem;
  color: #a6b7ce;
  font-style: italic;
  margin-bottom: 2rem;
}

/* Services Section */
.services-section {
  padding: 6rem 0;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  /* color: #60a5fa; */
  color:#42c4e3  ;
  margin-bottom: 3rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
}

.service-card {
  background: rgba(30, 41, 59, 0.5);
  padding: 2rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(96, 165, 250, 0.1);
}

.service-card:hover {
  transform: translateY(-5px);
  border-color: rgba(96, 165, 250, 0.3);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service-title {
  /* color: #60a5fa; */
  color:#42c4e3  ;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-description {
  color: #e2e8f0;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.service-benefits {
  list-style-type: none;
  color: #a6b7ce;
}

.service-benefits li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.service-benefits li::before {
  content: '→';
  position: absolute;
  left: 0;
  /* color: #60a5fa; */
  color:#42c4e3  ;

}

/* About Section */
.about-section {
  padding: 6rem 0;
  background: rgba(15, 23, 42, 0.5);
}

.about-content {
  display: grid;
  gap: 3rem;
  padding: 0 1rem;
}

.about-text {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.about-description {
  color: #e2e8f0;
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 3rem;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.stat-item {
  text-align: center;
  padding: 2rem;
  background: rgba(30, 41, 59, 0.5);
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-number {
  display: block;
  font-size: 2.5rem;
  font-weight: bold;
  /* color: #60a5fa; */
  color:#42c4e3  ;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #a6b7ce;
  font-size: 1.125rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.team-card {
  background: rgba(30, 41, 59, 0.5);
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
}

.team-member-avatar {
  width: 100px;
  height: 100px;
  background: #60a5fa;
  border-radius: 50%;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: white;
}

.team-member-name {
  color: #60a5fa;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.team-member-role {
  color: #e2e8f0;
  margin-bottom: 0.5rem;
}

.team-member-expertise,
.team-member-experience {
  color: #a6b7ce;
  font-size: 0.875rem;
}

/* Contact Section */
.contact-section {
  padding: 6rem 0;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  padding: 0 1rem;
}

.contact-info {
  display: grid;
  gap: 2rem;
}

.contact-method {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(30, 41, 59, 0.5);
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.contact-method:hover {
  transform: translateY(-5px);
}

.contact-icon {
  /* color: #60a5fa; */
  color:#42c4e3  ;

}

.contact-method h4 {
  /* color: #60a5fa; */
  color:#42c4e3  ;
  margin-bottom: 0.5rem;
}

.contact-method p {
  color: #e2e8f0;
}

.contact-form {
  background: rgba(30, 41, 59, 0.5);
  padding: 2rem;
  border-radius: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  background: rgba(15, 23, 42, 0.5);
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 0.5rem;
  color: white;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #60a5fa;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background: #2563eb;
}

/* Footer Styles */
.footer {
  background: rgb(0, 6, 19);
  padding: 4rem 2rem 2rem;
  margin-top: 4rem;
  box-shadow: 0 -2px 10px rgba(233, 232, 232, 0.253);
}
/* .footer {
  background: rgb(0, 6, 19);
  padding: 4rem 2rem 2rem;
  margin-top: 4rem;
} */

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.footer-section h4 {
  /* color: #60a5fa; */
  color:#42c4e3  ;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.footer-section p,
.footer-section a {
  color: #e2e8f0;
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: block;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #60a5fa;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: #e2e8f0;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #60a5fa;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(148, 163, 184, 0.1);
  color: #94a3b8;
}

/* Animations */
@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 4rem;
    left: 0;
    right: 0;
    background: rgba(15, 23, 42, 0.95);
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    transform: translateY(-150%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.show {
    transform: translateY(0);
  }

  .company-title {
    font-size: 2.5rem;
  }

  .contact-container {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 2rem;
  }

  .stats-container {
    grid-template-columns: 1fr;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }
}

/* Active Navigation Link */
.nav-links a.active {
  color: #60a5fa;
  position: relative;
}

.nav-links a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #60a5fa;
}

/* CTA Button */
.cta-button {
  display: inline-block;
  padding: 1rem 3rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background: #3b82f6;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background: #2563eb;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(59, 130, 246, 0.4);
}







































/* 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.landing-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  color: #ffffff;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(15, 23, 42, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
}

.nav-logo-img {
  width: 2.5rem;
  height: 2.5rem;
  animation: spin-slow 10s linear infinite;

}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #60a5fa;
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 1rem 2rem;
}

.hero-section {
  text-align: center;
  padding: 4rem 0;
}

.logo-container {
  position: relative;
  width: 8rem;
  height: 8rem;
  margin: 0 auto 1rem;
  border-radius: 50%;
  background: rgba(30, 41, 59, 0.5);
  padding: 0.2rem;
  animation: spin-slow 10s linear infinite;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #60a5fa 0%, #3b82f6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.company-tagline {
  font-size: 1.25rem;
  color: #a6b7ce;
  font-style: italic;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-card {
  padding: 2rem;
  background: rgba(30, 41, 59, 0.5);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(51, 65, 85, 0.5);
}

.feature-title {
  font-size: 1.5rem;
  color: #60a5fa;
  margin-bottom: 1rem;
}

.feature-description {
  color: #e2e8f0;
  line-height: 1.6;
}

.cta-button {
  display: block;
  margin: 2rem auto;
  padding: 1rem 3rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
  background: #3b82f6;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background: #2563eb;
  transform: translateY(-2px);
}

.footer {
  background: rgba(15, 23, 42, 0.9);
  padding: 4rem 2rem 2rem;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.footer-section h4 {
  color: #60a5fa;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.footer-section p {
  color: #e2e8f0;
  margin-bottom: 0.5rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  color: #e2e8f0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #60a5fa;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(148, 163, 184, 0.1);
  color: #94a3b8;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .company-title {
    font-size: 2.5rem;
  }

  .company-tagline {
    font-size: 1.25rem;
  }

  .logo-container {
    width: 6rem;
    height: 6rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
} */